/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital@0;1&display=swap');

/* Apply the font to the entire application */
body {
  font-family: 'Montserrat', sans-serif;
}

/* Add other global styles if needed */

/* Tailwind CSS directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

